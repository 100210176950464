<template>
  <div>
    <div class="mb-4 text-lg black95 bg-white py-8 border-b border-t px-4">支付记录</div>
    <div class=" sm:p-12 p-2 bg-white">
      <div class=" flex justify-end">
        <a-button type="primary" icon="money-collect" class="editable-add-btn" @click="handleRouteCreatePay">
          创建支付
        </a-button>
      </div>
      <a-table class="mt-4" :loading="dataLoding" bordered :data-source="dataSource" :columns="columns" rowKey="id" :pagination="false">
        <template slot="date" slot-scope="text">
          <span>
            {{ formatedDate(text) }}
          </span>
        </template>
        <template slot="amount" slot-scope="text, record">
          <div class="flex items-center font-600 black95">
            <span >{{ text }}</span>
            <span>{{ record.currency }}</span>
          </div>
        </template>
        <!-- <template slot="operation" slot-scope="text, record"> -->
        <template slot="operation" slot-scope="text">
            <a-tag
              :color="text === 'completed' ? 'green' : text === 'pending' ? 'volcano' : 'red'"
            >
            {{text.replace(/_/g, ' ')}}
            </a-tag>
        </template>
      </a-table>
      <div class=" mt-6 flex  justify-end">
        <a-pagination
        :current="currentPage"
        :total="total_entry"
        :pageSize="25"
        @change="pageOnchange" :defaultCurrent="1" />
      </div>
    </div>
  </div>
</template>
<script>
import { CreateCCPaymentList } from '@/api/common'
import { mapState, mapGetters } from 'vuex'

export default {
  created () {
    this.fetchData()
  },
  data () {
    return {
      currentPage: 1,
      total_entry: 25,
      dataLoding: false,
      dataSource: [
      ],
      count: 2,
      columns: [
        {
          key: 'refId',
          title: '交易编号',
          dataIndex: 'payment_id'
        },
        {
          key: 'amount',
          title: '金额',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'amount' }
        },
        {
          key: 'name',
          title: '日期(NZ)',
          dataIndex: 'payment_date',
          scopedSlots: { customRender: 'date' }
        },
        {
          key: 'status',
          title: '交易状态',
          dataIndex: 'status',
          width: '260px',
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      console.log('beforeRouteEnter')
      if (!vm.isCCactive) {
        vm.$message.error('Please activate your account first')
        next({ name: 'UserDash' })
      }
    })
  },

  methods: {
    fetchData () {
      this.dataLoding = true
      CreateCCPaymentList({ customer_id: this.customer.id, page: 1 }).then(res => {
        this.dataSource = res.payments
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.dataLoding = false
      })
    },
    pageOnchange (val) {
      this.currentPage = val
      this.fetchData(this.currentPage)
    },
    handleRouteCreatePay () {
      this.$router.push({ name: 'CCPaymentCreate' })
    },
    getCurrencyImgSrc (currency) {
      return require(`@/assets/currency/${currency}.png`)
    },
    onCellChange (key, dataIndex, value) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.dataSource = dataSource
      }
    },
    formatedDate (val) {
      const date = new Date(val)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
      const formatter = new Intl.DateTimeFormat('zh-CN', options)
      return formatter.format(date)
    },
    onDelete (key) {
      const dataSource = [...this.dataSource]
      this.dataSource = dataSource.filter(item => item.key !== key)
    }
  },
  computed: {
    ...mapState('cc', ['customer']),
    ...mapGetters('cc', ['isCCactive'])
  }
}
</script>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
